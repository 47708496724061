@MERGING-CURSOR: url("../assets/svg/cursors/merge-cursor.svg") 8 8, auto;
@TEXT_PRIMARY: hsl(var(--twc-text_primary));
@BACKGROUND: hsl(var(--twc-background));
@SELECTED_OUTLINE: @TEXT_PRIMARY solid 3px;

.TheoGraph {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;

  .react-flow {
    .react-flow__node {
      &.merging {
        box-shadow: 0px 0px 28px 0px color-mix(in lch, transparent 40%, @TEXT_PRIMARY);

        cursor: @MERGING-CURSOR;
        //background-color: @MERGING_COLOR;
        //.node-header {
        //  cursor: @MERGING-CURSOR;
        //}
      }

      &.inactive {
        opacity: .4;
      }

      .react-flow__handle{
        visibility: hidden;

      }
      .react-flow__handle-right  {
        width: 16px;
        height: 16px;
        background-color: @BACKGROUND;
        border-color: @TEXT_PRIMARY;
        border-width: 3px;
        margin-right: -2px;
      }
      &:hover {
        .react-flow__handle-right {
          visibility: visible;
        }
      }
    }

    .react-flow__attribution {
      display: none;
    }

    .react-flow__controls {
      pointer-events: all;
      flex-direction: row;
      display: flex;
      box-shadow: none;
    }

    .react-flow__edge.selected .react-flow__edge-path {
      stroke: #b1b1b7;
    }

    .react-flow__controls-button {
      border-bottom: none;
      background-color: hsl(var(--twc-background));

      > svg {
        fill: hsl(var(--twc-text_primary));
      }
    }
  }
}
