@ERROR_COLOR: #bb0000;

.tippy-box[data-theme~='warning'] {
    background-color: @ERROR_COLOR;
    color: white;
}
.tippy-box[data-theme~='warning'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: @ERROR_COLOR;
}
.tippy-box[data-theme~='warning'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: @ERROR_COLOR;
}
.tippy-box[data-theme~='warning'][data-placement^='left'] > .tippy-arrow::before {
    border-left-color: @ERROR_COLOR;
}
.tippy-box[data-theme~='warning'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: @ERROR_COLOR;
}

