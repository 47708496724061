@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./assets/fonts/Inter/inter.css');

html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  display: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.3);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

em {
  font-weight: bold;
}

.FocusContainer {
  border-width: 1px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.FocusContainer > input:hover,
.FocusContainer > .ant-select:hover .ant-select-selector {
  border-color: transparent;
}
.FocusContainer
.ant-select:not(.ant-select-customize-input)
.ant-select-selector,
.FocusContainer > input,
.FocusContainer > input:hover,
.FocusContainer
> .ant-select-focused.ant-select
.ant-select-selector
.FocusContainer
> .ant-select
.ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.FocusContainer .ant-input {
  background-color: transparent;
  border-width: 0;
}
.FocusContainer > .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: transparent;

  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px !important;
  outline: 0;
}
.FocusContainer > .ant-input-affix-wrapper {
  background-color: transparent !important;
}
.FocusContainer > .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: transparent !important;
  box-shadow: none;
  //box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  //border-right-width: 1px !important;
  //outline: 0;
}
.FocusContainer
> .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: transparent !important;
}
.FocusContainer > span.ant-select-clear:hover {
  background-color: transparent !important;
}
