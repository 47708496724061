.allotment-module_splitView__L-yRc.allotment-module_separatorBorder__x-rDS.allotment-module_vertical__WSwwa
> .allotment-module_splitViewContainer__rQnVa
> .allotment-module_splitViewView__MGZ6O:not(:first-child)::before {
  height: 1px;
  width: calc(100% - 32px);
  left: 16px;
}


.allotment-module_splitView__L-yRc.allotment-module_separatorBorder__x-rDS.allotment-module_horizontal__7doS8
> .allotment-module_splitViewContainer__rQnVa
> .allotment-module_splitViewView__MGZ6O:not(:first-child)::before {
  height: calc(100% - 32px);
  top: 16px;
  width: 1px;
}

.react-flow__controls-button {
  background-color: hsl(var(--twc-background));
  color: hsl(var(--twc-text_primary));
}
