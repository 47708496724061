.App {
  text-align: center;
}

html, body {
  background-color: hsl(var(--twc-background));
  color: hsl(var(--twc-text_primary));
}

:root {
  --focus-border: hsl(var(--twc-primary_light)) !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.selected {
  background-color: #99adf7;
}

.ActionButton.ant-btn-default {
  border-color: hsl(var(--twc-neutral-400));
  color: hsl(var(--twc-neutral-800));
  background-color: hsl(var(--twc-neutral-100));
  &:disabled {
    border-color: hsl(var(--twc-neutral-300));
    color: hsl(var(--twc-neutral-400));
    background-color: hsl(var(--twc-neutral-400));
  }
  &:hover:enabled {
    border-color: hsl(var(--twc-accent-500));
    color: hsl(var(--twc-accent-500));
    background: hsl(var(--twc-accent-100));
  }
  &:active:enabled {
    border-color: hsl(var(--twc-accent-500));
    color: hsl(var(--twc-accent-500));
    background: hsl(var(--twc-accent-100));
  }
}

.ActionButton.ant-btn-background-ghost {
@apply text-neutral-800 border-neutral-800;
  &:disabled {
  @apply bg-neutral-300 text-neutral-400;
    background: transparent;
  }
  &:hover:enabled {
  @apply bg-neutral-100 border-primary text-primary;
  }
  &:active:enabled {
  @apply border-primary bg-accent-100 text-primary;
  }
}

.BrandButton.ant-btn-primary {
@apply border-primary bg-primary text-white;
  &:disabled {
  @apply border-neutral-400 bg-neutral-400 text-white;
  }
  &:hover:enabled {
  @apply border-accent-700 bg-accent-700 text-white;
  }
  &:active:enabled {
  @apply border-primary_heavy bg-primary_heavy text-white;
  }
}

.BrandButton.ant-btn-background-ghost {
@apply text-neutral-800 border-neutral-600;
  background: white;
  &:disabled {
  @apply bg-neutral-300 text-neutral-400;
    background: transparent;
  }
  &:hover:enabled {
  @apply bg-accent-100 border-primary text-primary;
  }
  &:active:enabled {
  @apply border-primary bg-accent-100 text-primary;
  }
}

.auto_hide_container .auto_hide {
  visibility: hidden;
}
.auto_hide_container:hover .auto_hide {
  visibility: unset;
}

.ant-layout-header input {
  background-color: #335bef;
  color: #fff;
  border: 1px solid #0018eb;
}

.ant-layout-header .ant-input-affix-wrapper {
  background-color: #335bef;
}

.ant-layout-header input::placeholder {
  color: #0018eb;
}

input.crcInput {
  background-color: transparent;
  color: #fff;
  caret-color: #fff;
}

.crcInput > input::placeholder {
@apply text-neutral-500;
}

.crcInput > input {
  background-color: transparent;
  color: #fff;
  caret-color: #fff;
}

label.ant-radio-button-wrapper:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
label.ant-radio-button-wrapper:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-spin-dot-item {
@apply bg-primary/80;
}

.ant-tag,
.ant-select-selection-item {
  border-radius: 8px !important;
  align-items: center;
}
.ant-select-selection-item-remove {
  display: flex !important;
  align-items: center;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: rgba(0, 0, 0, 0.35);
}

.ant-switch {
@apply bg-neutral-800;
  &.ant-switch-checked {
  @apply bg-primary;
  }
}

.CheckAll {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
  @apply border-neutral-800;
  }
  .ant-checkbox-inner {
  @apply border-neutral-400;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
  @apply border-neutral-400 bg-neutral-400;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
  @apply border-neutral-800;
    &:hover {
    @apply border-neutral-400;
    }
    &::after {
    @apply bg-neutral-400;
    }
  }
}

.PrimaryConcept {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
  @apply border-neutral-800;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #335bef;
    background-color: #335bef;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
  @apply border-neutral-800;
    &:hover {
      border-color: #335bef;
    }
    &::after {
      background-color: #335bef;
    }
  }
}

.LinkedConcept {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
  @apply border-neutral-800;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #ffc043;
    background-color: #ffc043;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
  @apply border-neutral-800;
    &:hover {
      border-color: #ffc043;
    }
    &::after {
      background-color: #ffc043;
    }
  }
}

.Relation {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
  @apply border-neutral-800;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #05944f;
    background-color: #05944f;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
  @apply border-neutral-800;
    &:hover {
      border-color: #05944f;
    }
    &::after {
      background-color: #05944f;
    }
  }
}

.DefaultGray {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
  @apply border-neutral-800;
  }
  .ant-checkbox-inner {
  @apply border-neutral-600;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
  @apply border-neutral-600 bg-neutral-600;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
  @apply border-neutral-800;
    &:hover {
    @apply border-neutral-600;
    }
    &::after {
    @apply bg-neutral-600;
    }
  }
}

.Popup-selectionPopover {
  > .ant-popover-content {
  @apply bg-neutral-200;
    border-radius: 12px;
    > .ant-popover-arrow {
      display: none;
    }
    > .ant-popover-inner {
    @apply bg-neutral-200;
      border-radius: 12px;
      .ant-popover-title {
        padding: 0;
        border: none;
      }
      > .ant-popover-inner-content {
        padding: 0;
        border-radius: 0 0 12px 12px;
      }
    }
  }
}

/**
 * Hack way of targeting the evidence feedback
 * popover to remove the carat and box-shadow
 */
.feedback-popover {
  > .ant-popover-content {
    > .ant-popover-arrow {
      display: none;
    }
    > .ant-popover-inner {
      background-color: transparent;
      box-shadow: none;
      > .ant-popover-inner-content {
        padding: 0 8px;
      }
    }
  }
}

.tour-popover {
  > .ant-popover-content {
    > .ant-popover-inner {
      border-radius: 8px;
      > .ant-popover-inner-content {
        padding: 0px;
      }
    }
  }
}

.tour-popover {
  &:not(
      .ant-popover-placement-bottom,
      .ant-popover-placement-bottomRight,
      .ant-popover-placement-bottomLeft
    ) {
    > .ant-popover-content {
      > .ant-popover-arrow {
        > .ant-popover-arrow-content {
          &::before {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}
