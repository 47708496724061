@keyframes fadeout {
  from {opacity:1;}
  to {opacity:0;}
}

@keyframes fadein {
  from {opacity:0;}
  to {opacity:1;}
}

:root {
  --fadeTime: 1s;
}

.fadeout {
  animation: fadeout var(--fadeTime);
}

.fadein {
  animation: fadein var(--fadeTime);
}

.hidden {
  display: none;
}
